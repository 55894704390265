import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentRadioButton.figmaUrl.ios} codeUrl={checklists.componentRadioButton.codeUrl.ios} checklists={checklists.componentRadioButton.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Buttons are used to select only one of a limited number of choices`}</p>
    <p>{`Use Legion radio button that typically rendered as small circles, which are filled or highlighted when selected`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    import LegionUI
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`Legion Have 2 Variant of Radio Button :`}</p>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Checked ON`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F697517ce-0349-4d0f-8f4b-1e0321cdbbaf%2FScreen_Shot_2022-12-15_at_9.37.10_AM.png?id=466766db-c8dd-4db5-9a30-285d4992b0ba&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=90&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Checked OFF`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fa2502c36-87d6-4cdb-baf3-d73efe00decc%2FScreen_Shot_2022-12-15_at_9.38.15_AM.png?id=355c59bf-cbb7-4f22-822b-ab67673e36f1&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=100&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`Use this Function : `}<inlineCode parentName="p">{`LGNRadioBtn`}</inlineCode>{`and add value `}<inlineCode parentName="p">{`id`}</inlineCode>{` and function `}<inlineCode parentName="p">{`.setChecked(true)`}</inlineCode>{` for Checked ON or `}<inlineCode parentName="p">{`.setChecked(false)`}</inlineCode>{` for Checked OFF`}</p>
    <p>{`This code sample demonstrates how to modify the type of radio button :`}</p>
    <p>{`A. Checked ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
            id: "default",
            callback: { selectedId in
                // do something with selectedId
            }
        )
        .setChecked(true)
`}</code></pre>
    <p>{`B. Checked OFF`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
            id: "default",
            callback: { selectedId in
                // do something with selectedId
            }
        )
        .setChecked(false)
`}</code></pre>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <p>{`Legion Have 3 Attributes for Customize Radio Button :`}</p>
    <h3><strong parentName="h3">{` Size `}</strong></h3>
    <p>{`This size radio button attribute for user to choose size radio button.`}</p>
    <div {...{
      "className": "size",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F55db1318-4e20-43b2-b39f-24baaa88f469%2FScreen_Shot_2022-12-27_at_11.31.33_AM.png?id=71948a3f-5fe4-40c9-9d3a-47ebf23027e7&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=2000&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`Use this Function : `}<inlineCode parentName="p">{`.variant(size: SizeBtn)`}</inlineCode>{`, where the `}<inlineCode parentName="p">{`SizeBtn`}</inlineCode>{`consists of `}<inlineCode parentName="p">{`small`}</inlineCode>{`
and `}<inlineCode parentName="p">{`default`}</inlineCode>{` and finish with `}<inlineCode parentName="p">{`.setChecked(true)`}</inlineCode>{`for Checked ON and `}<inlineCode parentName="p">{`.setChecked(false)`}</inlineCode>{` for Checked OFF`}</p>
    <p>{`This code sample demonstrates how to modify size radio button :`}</p>
    <p>{`A. Radio Button Small & Checked ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
            id: "small",
            callback: { selectedId in
                // do something with selectedId
            }
        )
        .variant(size: .small)
        .setChecked(true)
`}</code></pre>
    <p>{`B. Radio Button Small & Checked OFF`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
            id: "small",
            callback: { selectedId in
                // do something with selectedId
            }
        )
        .variant(size: .small)
        .setChecked(false)
`}</code></pre>
    <p>{`C. Radio Button Default & Checked ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
            id: "default",
            callback: { selectedId in
                // do something with selectedId
            }
        )
        .variant(size: .default)
        .setChecked(true)
`}</code></pre>
    <p>{`D. Radio Button Default & Checked OFF`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
            id: "default",
            callback: { selectedId in
                // do something with selectedId
            }
        )
        .variant(size: .default)
        .setChecked(false)
`}</code></pre>
    <h3><strong parentName="h3">{` Disable `}</strong></h3>
    <p>{`This disable for mark the radio button that now have a value `}<inlineCode parentName="p">{`true`}</inlineCode></p>
    <div {...{
      "className": "size",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F55db1318-4e20-43b2-b39f-24baaa88f469%2FScreen_Shot_2022-12-27_at_11.31.33_AM.png?id=71948a3f-5fe4-40c9-9d3a-47ebf23027e7&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=2000&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`Use this Function : `}<inlineCode parentName="p">{`.variant(size: SizeBtn)`}</inlineCode>{`, where the `}<inlineCode parentName="p">{`SizeBtn`}</inlineCode>{`consists of `}<inlineCode parentName="p">{`small`}</inlineCode>{`
and `}<inlineCode parentName="p">{`default`}</inlineCode>{` . Add this `}<inlineCode parentName="p">{`.setChecked(true)`}</inlineCode>{`for Checked ON and `}<inlineCode parentName="p">{`.setChecked(false)`}</inlineCode>{` for Checked OFF. Last finish with `}<inlineCode parentName="p">{`.disableInteraction(true)`}</inlineCode></p>
    <p>{`This code sample demonstrates how to modify disable radio button :`}</p>
    <p>{`A. Radio Button Small Disabled & Checked ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
            id: "small",
            callback: { selectedId in
                // do something with selectedId
            }
        )
        .variant(size: .small)
        .setChecked(true)
        .disableInteraction(true)
`}</code></pre>
    <p>{`B. Radio Button Small Disabled & Checked OFF`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
            id: "small",
            callback: { selectedId in
                // do something with selectedId
            }
        )
        .variant(size: .small)
        .setChecked(false)
        .disableInteraction(true)
`}</code></pre>
    <p>{`C. Radio Button Default Disabled & Checked ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
            id: "default",
            callback: { selectedId in
                // do something with selectedId
            }
        )
        .variant(size: .default)
        .setChecked(true)
        .disableInteraction(true)
`}</code></pre>
    <p>{`D. Radio Button Default Disabled & Checked OFF`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
            id: "default",
            callback: { selectedId in
                // do something with selectedId
            }
        )
        .variant(size: .default)
        .setChecked(false)
        .disableInteraction(true)
`}</code></pre>
    <h3><strong parentName="h3">{` Label `}</strong></h3>
    <div {...{
      "className": "size",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F116fcb2a-b094-459d-8842-dd166a507162%2FScreen_Shot_2022-12-27_at_11.46.21_AM.png?id=79b91216-f2d9-46ff-b53f-4185fb882688&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=2000&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`Choose type radio button : `}<inlineCode parentName="p">{`.variant(size: SizeBtn)`}</inlineCode>{`, where the `}<inlineCode parentName="p">{`SizeBtn`}</inlineCode>{`consists of `}<inlineCode parentName="p">{`small`}</inlineCode>{`and `}<inlineCode parentName="p">{`default`}</inlineCode>{` And Add this `}<inlineCode parentName="p">{`label: "ValueOfLabel"`}</inlineCode></p>
    <p>{`This code sample demonstrates how to modify label of the button :`}</p>
    <p>{`A. Size Small , Disable ON & Checked ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
    id: "small",
    label: "Small",
    callback: { selectedId in
        // do something with selectedId
    }
)
.variant(size: .small)
.disableInteraction(true)
.setChecked(true)
`}</code></pre>
    <p>{`B. Size Default , Disable ON & Checked ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
    id: "default",
    label: "Default",
    callback: { selectedId in
        // do something with selectedId
    }
)
.variant(size: .default)
.disableInteraction(true)
.setChecked(true)
`}</code></pre>
    <p>{`C. Size Small, Disable ON & Checked OFF`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
    id: "small",
    label: "Small",
    callback: { selectedId in
        // do something with selectedId
    }
)
.variant(size: .small)
.disableInteraction(true)
.setChecked(false)
`}</code></pre>
    <p>{`D. Size Default, Disable ON & Checked OFF`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
    id: "default",
    label: "Default",
    callback: { selectedId in
        // do something with selectedId
    }
)
.variant(size: .default)
.disableInteraction(true)
.setChecked(false)
`}</code></pre>
    <p>{`E. Size Small, Disable OFF & Checked ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
    id: "small",
    label: "Small",
    callback: { selectedId in
        // do something with selectedId
    }
)
.variant(size: .small)
.disableInteraction(false)
.setChecked(true)
`}</code></pre>
    <p>{`F. Size Default, Disable OFF & Checked ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
    id: "default",
    label: "Default",
    callback: { selectedId in
        // do something with selectedId
    }
)
.variant(size: .default)
.disableInteraction(false)
.setChecked(true)
`}</code></pre>
    <p>{`G. Size Small. Disable OFF & Checked OFF`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
    id: "small",
    label: "Small",
    callback: { selectedId in
        // do something with selectedId
    }
)
.variant(size: .small)
.disableInteraction(false)
.setChecked(false)
`}</code></pre>
    <p>{`H. Size Default, Disable OFF & Checked OFF`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
    id: "default",
    label: "Default",
    callback: { selectedId in
        // do something with selectedId
    }
)
.variant(size: .default)
.disableInteraction(false)
.setChecked(false)
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the radio button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`not have`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label for the radio button’s that describes it`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fontFamily`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string of font family name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textForegroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A color to be used for the text label on the right, when the view is not disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary800`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textDisabledColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A color to be used for the text label on the right, when the view is disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary500`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isChecked`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The boolean state to check the current state of the radio button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`callback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The callback is to perform for radio button action and to return what is the id that has been selected. for example, you can add the logic to change checked radio button in the callback based on the id in the return`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`Example Project`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioBtn(
        id: "male",
        label: "Male",
        fontFamily: "Verdana-Italic",
        textForegroundColor: .green,
        textDisabledColor: .red,
        isChecked: false,
        callback: { selectedId in
            // do something with selectedId
        }
    )
    .setChecked(selectedId == "male")
    .variant(size: .default, isSpacerHidden: false)
    .disableInteraction(true)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      